:root {
  --hawk-green: #00cc00;
  --hawk-light: #e2e2e2;
  --bg-colour: #242424;
  --hawk-black: #181818;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Montserrat;
  background-color: var(--bg-colour);
  margin: 0;
  font-style: normal;
  font-weight: 200;
  color: var(--hawk-green);
}

h2 {
  color: var(--hawk-green);
  line-height: 60px;
}

button {
  background-color: var(--hawk-green);
  border: none;
  font-family: Montserrat;
  color: var(--hawk-light);
  padding: 15px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1vw;
}

button:hover {
  background-color: var(--hawk-light);
}

.header {
  background-color: var(--hawk-black);
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid #00cc00;
}

.userNameAvatarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  position: fixed;
  right: 0px;
}

.userName {
  font-family: Montserrat;
  font-style: bold;
  font-weight: 900;
  font-size: medium;
  color: var(--hawk-light);
  padding: 20px;
}

.fa-guest {
  color: var(--hawk-light);
}

.fa-guest:hover {
  color: var(--hawk-green);
}

.fa-user {
  color: var(--hawk-green);
}

.fa-user:hover {
  color: var(--hawk-green);
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--hawk-black);
  color: var(--hawk-light);
  text-transform: uppercase;
  font-weight: lighter;
  letter-spacing: 2px;
  display: flex;
  direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.footer-logo-container {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 20%;
  min-width: 100px;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer-logo {
  padding-left: 5px;
  padding-right: 5px;
}

.discordhover:hover {
  color: #7289da;
}

.twitterhover:hover {
  color: #1da1f2;
}

.imageStyle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

.headerLogo {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 179px;
  height: 80px;
}

.PT-Item {
  --primary: var(--hawk-green);
  width: 35vw;
  background: var(--hawk-black);
  height: 40vh;
  border-radius: 24px;
  border: 5px solid var(--hawk-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PT-Item:hover {
  border: 5px solid var(--hawk-light);
}

.PT-Item > * {
  padding: 20px;
}

.PT-Item p,
.PT-Item ul,
.PT-Item h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.PT-Heading {
  font-size: 12pt;
  text-align: center;
  color: var(--hawk-light);
  background: var(--primary);
  border-radius: 24px;
  margin-top: -4vh;
  margin-left: 15px;
  margin-right: 15px;
}

.PT-Feature {
  font-size: 12pt;
  padding: 8px 0;
  list-style: none;
}

.PT-Feature + .PT-Feature {
  border-top: 1px solid var(--hawk-light);
}

.PT-Footer {
  padding-top: 0;
  margin-top: auto;
  text-align: center;
}

.PT-Price {
  font-size: 14pt;
  color: var(--primary);
}

.PT-Price small {
  margin: 0 -0.25em;
  font-size: 12pt;
  color: #777;
}

.PT-Trial {
  font-size: 12pt;
  color: #777;
}
.PT-nb {
  font-size: 36pt;
  color: var(--hawk-green);
  padding: 5px;
}

.checkoutContainer {
  display: block;
  font-size: 4vh;
  color: var(--hawk-light);
  text-decoration: none;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 700;
  width: 90%;
}

.authContainer {
  display: block;
  font-size: 4vh;
  color: var(--hawk-light);
  text-decoration: none;
  position: fixed;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 700;
  width: 90%;
}

.authsmall {
  color: #777;
  font-size: 12px;
}

.sessionid {
  color: var(--hawk-green);
  font-size: 2.5vh;
}

.checkoutImage {
  text-align: center;
  width: 20%;
  padding-top: 40px;
  filter: invert(47%) sepia(70%) saturate(2559%) hue-rotate(86deg)
    brightness(105%) contrast(115%);
}
.checkoutImage:hover {
  filter: invert(46%) sepia(50%) saturate(7227%) hue-rotate(225deg)
    brightness(100%) contrast(91%);
}

.successMsg {
  font-weight: bold;
  font-size: 3vh;
  text-align: center;
  color: var(--hawk-light);
}

.customerLink {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  font-size: 1.5vw;
  color: var(--hawk-light);
  padding-top: 50px;
}
.stripeLogo {
  margin-left: auto;
  margin-right: auto;
}

.stripeLogo:hover {
  border: 5px solid var(--hawk-light);
}

.content {
  position: fixed;
  top: 150px;
  bottom: 110px;
  left: max(50px, 7vw);
  display: inline;
  overflow: auto;
  width: calc(100% - 7vw - 30px);
}

.termsText {
  color: var(--hawk-light);
  font-size: 14px;
  text-align: justify;
  padding-right: 30px;
  padding-left: 30px;
  line-height: 25px;
}

.termsHeader {
  font-weight: bold;
  color: var(--hawk-green);
}

.sidebar {
  width: 7vw;
  min-width: 50px;
  background-color: var(--hawk-black);
  position: fixed;
  top: 150px;
  bottom: 110px;
  left: 0;
  z-index: 1;
  overflow: auto;
}

.sidebar-item-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.sidebar-row {
  margin-top: 4vh;
  margin-bottom: 4vh;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
}

.sidebar-icon:hover {
  color: var(--hawk-green);
}
.sidebar-icon {
  border: 0px;
  text-decoration: none;
  outline: none;
  color: var(--hawk-light);
}

.sidebar-icon a:active {
  outline: none;
  color: var(--hawk-green);
}

.sidebar-icon:focus {
  color: var(--hawk-green);
}

.iconLink,
.iconLink:visited {
  text-align: center;
  text-decoration: none;
  color: inherit;
}

.link,
.link:visited {
  text-align: center;
  text-decoration: none;
  color: var(--hawk-green);
}

.hawk-logo {
  width: 52px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--hawk-green);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--hawk-light);
}

* {
  box-sizing: border-box;
}

.single-item {
  position: relative; /* Keep the layout intact */
  width: 640px; /* Reduce the width (previously 690px) */
  height: 370px; /* Maintain the current height */
  max-width: 90%; /* Allow responsiveness for smaller screens */
  margin: 20px; /* Add spacing between items */
  border: 6px solid var(--hawk-green); /* Keep the border design */
  border-radius: 20px; /* Maintain rounded corners */
  background: var(--hawk-black); /* Keep the background color */
  box-shadow: 10px 30px 50px -20px rgba(0, 0, 0, 0.1); /* Maintain shadow */
  box-sizing: border-box; /* Ensure padding and borders are included */
  margin-bottom: 40px; /* Adds space below each card */
  margin-top: 40px; /* Adds space below each card */
}

.single-item-customer {
  position: absolute;
  width: 640px; /* Match the original width */
  max-width: 90%; /* Allow it to be responsive */
  border: 6px solid var(--hawk-green); /* Keep the green border */
  border-radius: 20px; /* Maintain rounded corners */
  background: var(--hawk-black); /* Match the background */
  box-shadow: 10px 30px 50px -20px rgba(0, 0, 0, 0.1); /* Keep the shadow */
  padding: 20px; /* Add padding for spacing */
  box-sizing: border-box; /* Include padding in size */
  min-height: 400px; /* Ensure minimum height for content */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left-set,
.right-set {
  position: relative;
  width: 50%;
  height: 100%;
}
.customer {
  width: 100%;
}

.left-set {
  float: left;
  background: linear-gradient(#181818 50%, #e2e2e2 50%);
  height: 450px;
  width: 260px;
  margin-left: 10px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  margin-top: -50px;
  border-radius: 12px;
}
.left-set img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 230px;
  height: 230px;
}

.right-set {
  float: right;
  padding: 2rem 1rem;
  box-sizing: border-box;
}
.right-set .subname {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1rem;
  color: var(--hawk-light);
}

.subname-customer {
  text-align: center;
}

.right-set .price {
  padding: 1.2rem 0;
  font-size: 2.5rem;
  font-weight: bold;
}

.right-set .pricesmall {
  font-size: 1rem;
  color: #777;
}

.right-set .captchatype {
  font-size: 0.8rem;
  color: #777;
}

.right-set .description p {
  font-size: 0.9rem;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: var(--hawk-light);
}

.right-set .subdescription p {
  font-size: 0.6rem;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: var(--hawk-light);
}

.right-set .description-customer p {
  font-size: 0.9rem;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: var(--hawk-light);
  padding-top: 40px;
  padding-left: 20px;
  line-height: 25px;
}

.right-set .description-captcha p {
  font-size: 0.7rem;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: var(--hawk-light);
}

.right-set .color {
  margin: 1rem 0;
}
.right-set .color:after {
  content: "";
  display: block;
  clear: left;
}
.right-set button {
  width: 95%;
  height: 60px;
  margin-top: 15px;
  border-radius: 20px;
  border: none;
  background: var(--hawk-green);
  box-shadow: 0px 0px 18px var(--hawk-green);
  color: var(--hawk-light);
  cursor: pointer;
  font-size: 1rem;
}

.right-set button:hover {
  background: var(--hawk-light);
  color: var(--hawk-black);
  box-shadow: 0px 0px 18px var(--hawk-light);
}

.rectangle {
  width: 720px;
  height: 85px;
  margin-left: auto;
  margin-right: auto;
  background: var(--hawk-green);
  color: var(--hawk-black);
  padding: 25px;
  border-radius: 40px;
  font-size: 32px;
  box-shadow: 0px 0px 18px var(--hawk-green);
  cursor: pointer;
}

.stepper-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.stepper-input button {
  cursor: pointer;
  color: #fff;
  background-color: var(--hawk-green);
  width: 20%;
  height:10%;
  border-radius: 15px;
  border: 1px solid var(--hawk-green);
  box-shadow: none;
  font-size: 1.4rem;
  padding: 0px;
  margin-top: 0px;
}

.stepper-input button:hover {
  background-color: var(--hawk-black);
  color: var(--hawk-green);
  box-shadow: none;
}

.stepperbtn-left {
  border-radius: 10px 0 0 10px;
}
.stepperbtn-right {
  border-radius: 0 10px 10px 0;
}
.stepperinput-box {
  border: 1px solid var(--hawk-green);
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  background-color: var(--hawk-black);
  color: #fff;
  height: 30px;
  width: 30%;
  margin-left: 20px;
  margin-right: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  height: 71vh; /* Subtract header/footer height from viewport height */
  box-sizing: border-box;
  gap: 80px; /* Add space between items */
}


@media (max-width: 767px) {

  .stepper-input button:hover {
    background-color: var(--hawk-green);
    color: var(--hawk-green);
  }

  .left-set {
    width: 93%;
    border-radius: 12px;
    margin-top: 0px;
    height: 160px;
  }
  .left-set img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
  }

  .right-set {
    float: left;
    width: 90%;
    height: auto;
    position: absolute;
    top: 65%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .right-set .subname {
    font-weight: bold;
    font-size: 1.3rem;
    color: var(--hawk-light);
    padding-top: 0px;
  }

  .right-set .price {
    padding: 1.2rem 0;
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 0;
    padding-top: 10px;
  }

  .right-set button {
    width: 100%;
    height: 60px;
    margin-top: 15px;
    border-radius: 20px;
    border: none;
    background: var(--hawk-green);
    box-shadow: 0px 0px 18px var(--hawk-green);
    color: var(--hawk-light);
    cursor: pointer;
    font-size: 1rem;
  }

  /* .single-item {
    width: 90%;
    height: 520px;
    top: 0;
    margin-top: 0 auto;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  } */

  .sidebar {
    width: 100%;
    height: 6vh;
    background-color: var(--hawk-black);
    position: fixed;
    top: 100px;
    left: 0;
    z-index: 1;
    overflow: auto;
  }

  .sidebar-item-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
  }

  .userName {
    display: none;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--hawk-black);
    color: var(--hawk-light);
    text-transform: uppercase;
    font-weight: lighter;
    letter-spacing: 2px;
    display: flex;
    direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 9px;
  }

  .hawk-logo {
    position: fixed;
    left: 10px;
    bottom: 14px;
  }

  .footer-logo-container {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 30%;
    height: 60px;
    justify-content: space-between;
    padding-right: 10px;
  }

  .content {
    position: fixed;
    top: 160px;
    bottom: 80px;
    left: 0px;
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    overflow: auto;
    width: 100%;
  }

  .termsText {
    color: var(--hawk-light);
    font-size: 14px;
    text-align: justify;
    margin-top: 25px;
    margin-left: 0px;
    line-height: 25px;
  }

  .imageStyle {
    position: absolute;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    left: 50%;
  }

  .right-set .description-customer p {
    font-size: 0.9rem;
    -webkit-hyphens: auto;
    hyphens: auto;
    color: var(--hawk-light);
    padding-top: 5px;
    padding-left: 5px;
    line-height: 25px;
  }

  .single-item-customer {
    position: absolute;
    left: 50%;
    top: 5%;
    width: 690px;
    height: 320px;
    max-width: 100%;
    border: 6px solid var(--hawk-green);
    border-radius: 20px;
    background: var(--hawk-black);
    box-shadow: 10px 30px 50px -20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }

  .customer {
    width: 100%;
    padding-top: 10px;
    top: 40%;
  }

  .flex-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    height: auto; /* Allow the height to adjust based on content */
    gap: 40px; /* Reduce gap between stacked items */
    padding: 20px; /* Add padding for spacing */
  }

  .single-item {
    position: relative;
    width: 640px; /* Keep the width */
    max-width: 80%; /* Allow responsiveness */
    min-height: 420px; /* Ensure it maintains a baseline height */
    height: auto; /* Allow it to grow for additional content */
    margin: 20px auto; /* Center and add spacing */
    border: 6px solid var(--hawk-green);
    border-radius: 20px;
    background: var(--hawk-black);
    box-shadow: 10px 30px 50px -20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 20px; /* Add padding for spacing within the card */
    margin-top: 50px;
  }
  

  .left-set,
  .right-set {
    width: 100%; /* Make inner elements take full width */
    height: auto; /* Allow content height to adjust */
  }

  .left-set img {
    width: 200px; /* Scale the image to fit smaller screens */
    height: auto; /* Maintain aspect ratio */
  }
}
